.card-item {
  margin: 1rem 0;
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;

  &:hover &:active {
    box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
  }
}

.cardd {
  text-align: center;
  padding: 2rem 0;
  font-size: 2rem;
}
