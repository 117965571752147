.navbarr {
  position: fixed;
  left: 50%;
  transform: translateX(-50%);
  z-index: 1;

  &-list {
    -webkit-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.25);
    -moz-box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.25);
    box-shadow: 5px 5px 20px 0px rgba(0, 0, 0, 0.25);
    display: flex;
    justify-content: center;
    background-color: $color-white;
    border-top: none;
    list-style-type: none;
    overflow: hidden;
    padding: 0 4rem;
    border-bottom-left-radius: 3rem;
    border-bottom-right-radius: 3rem;
  }

  &-item {
    float: left;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;

    &:hover {
      transform: scale(1.075);
      background-color: $color-light;
    }

    & a {
      white-space: nowrap;
      font-weight: normal;
      display: block;
      color: $color-black-smoky;
      padding: 1rem 1rem;
      text-decoration: none;
    }
  }

  &-active {
    visibility: visible;
    transition: all .3s ease-in;
  }

  &-hidden {
    visibility: hidden;
    transition: all .3s ease-out;
    transform: translate(-50%, -100%);
  }
}
