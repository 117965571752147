.text-center {
  text-align: center;
}
.text-right {
  text-align: right;
}
.text-left {
  text-align: left;
}

.justify-center {
  align-self: flex-end;
}

.vh100 {
  height: 100vh;
}

.h100 {
  height: 100%;
}

.vw100 {
  width: 100vw;
}

.w100 {
  width: 100%;
}

.px-5 {
  padding: 0 5rem;
}
