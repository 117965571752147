*,
*::after,
*::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 100%;

  @include respond(tab-land) { // width < 1200?
    font-size: 90%; //1 rem = 9px, 9/16 = 50%
  }

  @include respond(tab-port) { // width < 900?
    font-size: 80%; //1 rem = 8px, 8/16 = 50%
  }

  @include respond(big-desktop) {
    font-size: 110%; //1rem = 12, 12/16
  }
}

p {
  color: $color-grey-nickel;
  padding: .5rem 0;
}
