// COLORS
$color-black-smoky: #0F0F0F;
$color-choco-dark: #3a3a3a;
$color-grey-nickel: #706F6F;
$color-grey-light: #ccc9c8;
$color-light: #f5f5f5;
$color-white: #FFF;


// GRID
$grid-width: 114rem;
$gutter-vertical: 8rem;
$gutter-vertical-small: 6rem;
$gutter-horizontal: 6rem;
