.products {
  background-color: rgba($color-grey-light, .2);
  background-repeat: no-repeat;
  background-size: cover;
  width: 100%;
  padding: 2rem 0;
  margin: 2rem 0;
  &-icon {
    margin: .5rem auto;
    width: 50%;
    transition: all .25s;
    &:hover, &:active {
      transform: scale(1.1);
      -webkit-filter: drop-shadow( 1rem 1rem 1rem rgba(0, 0, 0, .2));
      filter: drop-shadow( 1rem 1rem .5rem rgba(0, 0, 0, .2));
    }
  }
  &-text {
    font-size: 1rem;
    color: $color-choco-dark;
  }
}
