.lang {
  &-list {
    display: flex;
    justify-content: center;
    margin-bottom: 4.5rem;
  }
  &-item {
    display: inline;
    transition: all .25s;
    &:hover {
      transform: translateY(-5%);
    }
  }
  &-button {
    padding: 0 1rem;
    border: 0;
    border-right: 1px solid $color-grey-nickel;
    -webkit-transition: all .25s;
    -moz-transition: all .25s;
    -ms-transition: all .25s;
    -o-transition: all .25s;
    transition: all .25s;
    cursor: pointer;
  }
}

.Polska {
  border-right: none !important;
}
