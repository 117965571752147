.certificates {
  text-align: center;
  margin-top: 10vh;
  margin-bottom: 10vh;

  &-title {
    font-size: 2rem !important;
  }

  &-item {
    transition: all .25s;

    &:hover {
      transform: translateY(-5%);
    }
  }

  &-link {
    transition: all .25s;
    &:hover {
      color: black;
      text-decoration: none;
      text-shadow: 0 5px 2px rgba(0,0,0,.125) ;
    }
  }
}