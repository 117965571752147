.about {
  position: relative;
  margin: 0 0;
  padding: 1rem;
  &-back {
    white-space: nowrap;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    text-transform: uppercase;
    font-size: 9rem;
    color: #f3f3f3;
    letter-spacing: .5rem;
    z-index: -1;
  }
  &-block {
    padding: 2rem;
    border-radius: .5rem;
  }
}

@media only screen and (max-width: 768px) {
  .about-back {
    font-size: 10rem;
  }
}

@media only screen and (max-width: 600px) {
  .about-back {
    font-size: 7rem;
  }
}
