.logo {
  width: 50%;
  fill: white;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -130%);
  -webkit-filter: drop-shadow( 1rem 1rem 1rem rgba(0, 0, 0, 1));
  filter: drop-shadow( 1rem 1rem .5rem rgba(0, 0, 0, 1));
  transition: all .25s;
  &:hover {
    -webkit-filter: drop-shadow( 1.5rem 1.5rem 1rem rgba(0, 0, 0, 1));
    filter: drop-shadow( 1.5rem 1.5rem 1rem rgba(0, 0, 0, 1));
    transform: translate(-50%, -145%) scale(1.2);
  }
}

@media only screen and (max-width: 600px) {
  .logo {
    width: 80%;
  }
}
@media only screen and (min-width: 1200px) {
  .logo {
    width: 35%;
  }
}
